import React, { Fragment, Suspense } from 'react';
import Preloader from '../elements/Preloader';
import { Link } from 'react-router-dom';

const SpecialProcessContent = React.lazy(() =>
  import('../components/SpecialProcessContent')
);
const NavbarFive = React.lazy(() => import('../components/NavbarFive'));
const BreadcrumbTwo = React.lazy(() => import('../components/BreadcrumbTwo'));
const FooterBottomOne = React.lazy(() =>
  import('../components/FooterBottomOne')
);
const FooterOne = React.lazy(() => import('../components/FooterOne'));
const BlogView = React.lazy(() => import('../components/BlogView'));

const SpecialProcess = () => {
  return (
    <>
      <Fragment>
        <Suspense fallback={<Preloader />}>
          {/* Navbar One */}
          <NavbarFive />

          {/* Breadcrumb */}
          <BreadcrumbTwo title={'Facilities'} />

          {/* SpecialProcessContent */}
          <SpecialProcessContent />

          {/* Footer One */}
          <FooterOne />

          {/* Footer Bottom One */}
          <FooterBottomOne />
        </Suspense>
      </Fragment>
    </>
  );
};

export default SpecialProcess;
