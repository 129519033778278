import React, { Fragment, Suspense } from 'react';
import Preloader from '../elements/Preloader';

const NavbarFive = React.lazy(() => import('../components/NavbarFive'));
const ServiceDetailsInnerOne = React.lazy(() =>
  import('../components/ServiceDetailsInnerOne')
);
const BreadcrumbTwo = React.lazy(() => import('../components/BreadcrumbTwo'));
const FooterBottomOne = React.lazy(() =>
  import('../components/FooterBottomOne')
);
const FooterOne = React.lazy(() => import('../components/FooterOne'));

const Facilities = () => {
  return (
    <>
      <Fragment>
        <Suspense fallback={<Preloader />}>
          {/* Navbar One */}
          <NavbarFive />

          {/* Breadcrumb */}
          <BreadcrumbTwo title={'Facilities'} />

          {/* Service One All */}
          <ServiceDetailsInnerOne />

          {/* Footer One */}
          <FooterOne />

          {/* Footer Bottom One */}
          <FooterBottomOne />
        </Suspense>
      </Fragment>
    </>
  );
};

export default Facilities;
