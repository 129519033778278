import React, { Fragment, Suspense } from 'react';
import Preloader from '../elements/Preloader';
import ServiceTwoAll from '../components/ServiceTwoAll';

const ProductView = React.lazy(() => import('../components/ProductView'));
const BannerOne = React.lazy(() => import('../components/BannerOne'));
const NavbarFive = React.lazy(() => import('../components/NavbarFive'));
const LogisticsOne = React.lazy(() => import('../components/LogisticsOne'));
const TransportServiceArea = React.lazy(() =>
  import('../components/TransportServiceArea')
);
const FooterBottomOne = React.lazy(() =>
  import('../components/FooterBottomOne')
);
const FooterOne = React.lazy(() => import('../components/FooterOne'));
const HomeOne = () => {
  return (
    <>
      <Fragment>
        <Suspense fallback={<Preloader />}>
          {/* Navbar One */}
          <NavbarFive />

          {/* Banner One */}
          <BannerOne />

          {/* About One */}
          <LogisticsOne />

          {/* <ServiceOne /> */}
          <TransportServiceArea />

          <ServiceTwoAll />

          {/* Team One */}
          {/* <ProductView /> */}

          {/* Footer One */}
          <FooterOne />

          {/* Footer Bottom One */}
          <FooterBottomOne />
        </Suspense>
      </Fragment>
    </>
  );
};

export default HomeOne;
