import React from 'react';

const Timeline = () => {
  return (
    <>
      <div class='timeline'>
        <h2 class='title' style={{ margin: 'auto', marginBottom: '3rem' }}>
          JOURNEY
        </h2>
        <div class='timeline__event animated fadeInUp delay-1s timeline__event--type3'>
          <div class='timeline__event__date'>2023</div>
          <div class='timeline__event__content'>
            <div class='timeline__event__title'>FALCON Precision Products</div>
            <div class='timeline__event__description'>
              <p>
                Falcon Machining specializes in providing precision CNC
                machining services, utilizing state-of-the-art technology.
                One-Stop Shop for Precision Manufacturing and Assembly Solutions
                for aerospace , automotive , medical and other domains.
              </p>
            </div>
          </div>
        </div>

        <div class='timeline__event animated fadeInUp delay-2s timeline__event--type2'>
          <div class='timeline__event__date'>2013</div>
          <div class='timeline__event__content'>
            <div class='timeline__event__title'>BUNNY Industries</div>
            <div class='timeline__event__description'>
              <p>
                Established to manufacture high precision products for
                aerospace, defence, automotive and others by using CNC machine
                technology
              </p>
            </div>
          </div>
        </div>

        <div class='timeline__event  animated fadeInUp delay-3s timeline__event--type1'>
          <div class='timeline__event__date'>2007</div>
          <div class='timeline__event__content '>
            <div class='timeline__event__title'>KJL Engineering Works</div>
            <div class='timeline__event__description'>
              <p>
                Established to manufacture mechanical components by using
                conventional machines for automotive domain
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Timeline;
