import React from 'react';

const ServiceTwoAll = () => {
  return (
    <>
      {/* service area start */}
      <div className='service-area style-2 pd-top-100 pd-bottom-100 '>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-7'>
              <div className='section-title text-center'>
                <h2 className='title'>INDUSTRIES</h2>
              </div>
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-lg-4'>
              <div className='single-service-wrap'>
                <div className='thumb'>
                  <img
                    src='assets/img/client_image/Image005.jpeg'
                    alt='Falcon'
                  />
                  <div className='icon'>
                    <img src='assets/img/icon1.png' alt='Falcon' />
                  </div>
                </div>
                <div className='details'>
                  <h5>Aerospace</h5>
                </div>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='single-service-wrap'>
                <div className='thumb'>
                  <img
                    src='assets/img/client_image/image006.jpeg'
                    alt='Falcon'
                  />
                  <div className='icon'>
                    <img src='assets/img/icon1.png' alt='Falcon' />
                  </div>
                </div>
                <div className='details'>
                  <h5>Oil and Gas</h5>
                </div>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='single-service-wrap'>
                <div className='thumb'>
                  <img
                    src='assets/img/client_image/image007.jpeg'
                    alt='Falcon'
                  />
                  <div className='icon'>
                    <img src='assets/img/icon1.png' alt='Falcon' />
                  </div>
                </div>
                <div className='details'>
                  <h5>Automobile</h5>
                </div>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='single-service-wrap'>
                <div className='thumb'>
                  <img
                    src='assets/img/client_image/image008.jpg'
                    alt='Falcon'
                  />
                  <div className='icon'>
                    <img src='assets/img/icon1.png' alt='Falcon' />
                  </div>
                </div>
                <div className='details'>
                  <h5>Semiconductor</h5>
                </div>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='single-service-wrap'>
                <div className='thumb'>
                  <img
                    src='assets/img/client_image/image 009.jpeg'
                    alt='Falcon'
                  />
                  <div className='icon'>
                    <img src='assets/img/icon1.png' alt='Falcon' />
                  </div>
                </div>
                <div className='details'>
                  <h5>Medical</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* service area end */}
    </>
  );
};

export default ServiceTwoAll;
