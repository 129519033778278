import classes from "./Vission.module.css";
import React, { useState, useEffect, useRef } from "react";

const Vission = () => {
  const [isLeftCardVisible, setIsLeftCardVisible] = useState(false);
  const [isRightCardVisible, setIsRightCardVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const sectionTop = sectionRef.current.offsetTop;
      const sectionBottom = sectionTop + sectionRef.current.offsetHeight;
      const scrollPosition = window.scrollY + window.innerHeight;

      if (scrollPosition > sectionTop && scrollPosition < sectionBottom) {
        setIsLeftCardVisible(true);
        setIsRightCardVisible(true);
      } else {
        setIsLeftCardVisible(false);
        setIsRightCardVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <section
        className={classes["vision-mission-section"]}
        ref={sectionRef}
        style={{ background: "url(assets/img/service/bg.png)" }}
      >
        <div className={classes.container}>
          <div
            className={`${classes.card} ${
              isLeftCardVisible ? classes["left-to-center"] : ""
            }`}
            style={{ background: "url(assets/img/fact/bg.png)" }}
          >
            <div className={classes["card-content"]}>
              <h2 style={{ color: "#fff" }}>OUR MISSION</h2>
              <p
                className={classes["animated-paragraph"]}
                style={{ color: "#fff" }}
              >
                Provide superior manufacturing and assembly services to our
                customers for aerospace, defense, medical, automotive, and other
                industries to grow as a network of innovative businesses that
                will focus on serving customer needs.
              </p>
            </div>
          </div>
          <div
            className={`${classes.card} ${
              isRightCardVisible ? classes["right-to-center"] : ""
            }`}
            style={{ background: "url(assets/img/fact/bg.png)" }}
          >
            <div className={classes["card-content"]}>
              <h2 style={{ color: "#fff" }}>OUR VISION</h2>
              <p
                className={classes["animated-paragraph"]}
                style={{ color: "#fff" }}
              >
                To be a Global Leader in the design and manufacture of high
                precision products.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Vission;
