import React, { Fragment, Suspense } from 'react';
import Preloader from '../elements/Preloader';
import Timeline from '../components/Timeline';
import Vission from '../components/Vission';

const NavbarFive = React.lazy(() => import('../components/NavbarFive'));
const BreadcrumbThree = React.lazy(() =>
  import('../components/BreadcrumbThree')
);
const AboutOne = React.lazy(() => import('../components/AboutOne'));
const FooterBottomOne = React.lazy(() =>
  import('../components/FooterBottomOne')
);
const CounterOne = React.lazy(() => import('../components/CounterOne'));
const FooterOne = React.lazy(() => import('../components/FooterOne'));

const About = () => {
  return (
    <>
      <Fragment>
        <Suspense fallback={<Preloader />}>
          {/* Navbar One */}
          <NavbarFive />

          {/* Breadcrumb */}
          <BreadcrumbThree
            title={'ABOUT US'}
            image='url(assets/img/banner/breadcrumb-04.jpg)'
          />

          {/* About One */}
          <div className='pd-top-120 pd-bottom-40'>
            <AboutOne />
          </div>

          <div className='fact-area' style={{ background: '#f9f9f9' }}>
            {/* <Vission /> */}
            <Vission />
          </div>

          {/* <Timeline /> */}
          <Timeline />

          {/* Footer One */}
          <FooterOne />

          {/* Footer Bottom One */}
          <FooterBottomOne />
        </Suspense>
      </Fragment>
    </>
  );
};

export default About;
